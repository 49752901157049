<template>
  <div class="container-fluid">
    <div class="row" v-if="dir==''">
      <div v-for="s in sections" class="col-12 col-sm-6 col-md-3 col-xl-2 mb-3" :key="s.tag">
        <div class="card">
          <img class="card-img-top" :src="'https://infotechnic.tecnoclub.org/images-next/' + s.tag +'.jpg'"
               alt="Card image cap">
          <div class="card-body">
            <h5 class="card-title">{{ TEXT(s.title) }}</h5>
            <p class="card-text small">{{ TEXT(s.text) }}</p>
            <a href="#" class="btn btn-primary" @click="getDir(s.tag)">{{ TEXT('view_processes') }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- End row -->
    <div class="row" v-else>
      <div class="container">
        <button class="btn btn-dark mb-3" @click="goBack"><i class="fas fa-arrow-alt-left"></i> Atrás</button>
        <table class="table table-dark table-striped table-hover">
          <tbody style="cursor:pointer">
          <tr @click="goTo(f,dir+'/'+f+'.pdf')" v-for="f in files" :key="f">
            <td>{{ f }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- End row -->

    <docs-viewer :title="title" :url="url" ref="viewer"></docs-viewer>


  </div>
</template>

<script>
import DocsViewer from "@/components/DocsViewer.vue";
import {mapGetters} from "vuex";
import api from "@/api";

export default {
  name: "process-view",
  components: {DocsViewer},
  data() {
    return {
      sections: [
        {title: 'sensors', tag: 'sensores', text: 'sensors_potentiometers_pickups'},
        {title: 'actuators', tag: 'actuadores', text: 'solenoid_valves_servomotors_regulators_injectors'}
      ],
      dir: '',
      files: [],
      viewer: false,
      title: '',
      url: ''
    }
  },
  computed: {
    ...mapGetters([
      'TEXT'
    ])
  },
  methods: {
    getDir(d) {
      this.dir = d;
      api.GET_LESSONS_FILES('procesos/' + d)
          .then(response => {
            if (!response.data.auth) {
              this.$emit('logout')
            } else {
              this.files = response.data.list
            }
          });
    },
    goBack() {
      this.files = [];
      this.dir = '';
    },
    goTo(title, url) {
      // window.open('https://' + PARTNER + '.tecnoclub.org/docs/procesos/' + url);
      this.title = title
      this.url = 'https://docs.infotechnic.es/procesos/' + url
      this.$refs.viewer.show()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../theme';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
</style>
