<template>
  <div class="container-fluid">
    <h1>Cursos por vehículo</h1>
    <div class="row" v-if="dir==''">
      <!--          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeHOYopDCsM21Qfi6PLSXtjv5cTZ9wiRjxJrot9tmrpNnM4SA/viewform?embedded=true" width="640" height="2888" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>-->
      <div v-for="s in sections" class="col-12 col-sm-6 col-md-3 col-xl-2 mb-3" :key="s.tag">
        <div class="card h-100">
          <img class="card-img-top pa-5" :src="'https://infotechnic.tecnoclub.org/logos/' + s.logo +'.png'"
               alt="Card image cap" style="max-width: 130px">
          <div class="card-body">
            <h5 class="card-title">{{ s.title }}</h5>
            <div class="btn-group">
              <a href="#" class="btn btn-primary" @click="getDir(s.tag)"><small>Ver Temarios</small></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End row -->
    <div class="row" v-else>
      <div class="container">
        <button class="btn btn-dark mb-3" @click="goBack"><i class="fas fa-arrow-alt-left"></i> Atrás</button>
        <table class="table table-dark table-striped table-hover">
          <tbody style="cursor:pointer">
          <tr @click="goTo(f, dir+'/'+f+'.pdf')" v-for="f in files" :key="f">
            <td>{{ f }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- End row -->

    <docs-viewer :title="title" :url="url" ref="viewer"></docs-viewer>

  </div>
</template>

<script>
import docsViewer from "@/components/DocsViewer.vue";
import api from "@/api";

export default {
  name: "vehicle-lessons-view",
  components: {
    docsViewer
  },
  data() {
    return {
      sections: [
        {
          title: 'Curso BMW',
          tag: 'bmw',
          logo: 'bmw',
        },
        {
          title: 'Curso GLP (Fiat)',
          tag: 'fiat-glp',
          logo: 'fiat',
        },
        {
          title: 'Curso Hyundai',
          tag: 'hyundai',
          logo: 'hyundai',
        },
        {
          title: 'Curso Mazda',
          tag: 'mazda',
          logo: 'mazda',
        },
        {
          title: 'Curso Mercedes-Benz',
          tag: 'mercedes',
          logo: 'mercedes-benz',
        },
        {
          title: 'Curso DV5R (PSA)',
          tag: 'psa-dv5r',
          logo: 'peugeot',
        },
        {
          title: 'Curso DV6 (PSA)',
          tag: 'psa-dv6',
          logo: 'peugeot',
        },
        {
          title: 'Curso PassTrough (PSA)',
          tag: 'psa-passtrough',
          logo: 'peugeot',
        },
        {
          title: 'Curso Renault',
          tag: 'renault',
          logo: 'renault',
        },
        {
          title: 'Curso Toyota',
          tag: 'toyota-prius',
          logo: 'toyota',
        },
        {
          title: 'Curso Diesel (VAG)',
          tag: 'vag-diesel',
          logo: 'volkswagen',
        },
        {
          title: 'Curso Gasolina (VAG)',
          tag: 'vag-petrol',
          logo: 'volkswagen',
        },
        {
          title: 'Curso Motores TSI/TFSI (VAG)',
          tag: 'vag-tsi',
          logo: 'volkswagen',
        },
        {
          title: 'Curso Esquemas (VAG)',
          tag: 'vag-wiring',
          logo: 'volkswagen',
        },
        {
          title: 'Curso Nuevos Motores (VAG)',
          tag: 'vag-news',
          logo: 'volkswagen',
        },
      ],
      dir: '',
      files: [],
      viewer: false,
      title: '',
      url: ''
    }
  },
  methods: {
    getDir(d) {
      this.dir = d;
      api.GET_LESSONS_FILES('brands/'+d)
          .then(response => {
            if (!response.data.auth) {
              this.$emit('logout')
            } else {
              this.files = response.data.list
            }
          });
    },
    goBack() {
      this.files = [];
      this.dir = '';
    },
    goTo(title, url) {
      this.title = title
      this.url = 'https://docs.infotechnic.es/brands/' + url
      this.$refs.viewer.show()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../theme';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
</style>
