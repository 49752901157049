<template>
  <div class="container-fluid">
    <div class="d-inline-flex">
      <img :src="'https://assets.infotechnic.es/logos/'+vehicle.maker.toLowerCase()+'.png'" alt=""
           class="img-fluid mr-2"
           style="height: 50px">
      <h1>{{ vehicle.maker }} {{ vehicle.model }} ({{ vehicle.motor }})</h1>
    </div>
    <hr class="mt-1 mb-2">
    <div class="row px-lg-0">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2" v-for="c in categories" :key="c.id">
        <div class="card text-white bg-primary">
          <a href="#" @click.prevent="setCategory(c.id)" :disabled="getCount(c.id)">
            <img :src="'https://assets.infotechnic.es/categories/'+c.tag+'.jpg'" class="card-img-top" alt="">
          </a>
          <div class="card-body py-2">
            <h4 class="card-title m-0">{{ c.name }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import api from "@/api";

export default {
  name: "CategorySelector",
  data() {
    return {
      categories: [
        {id: 0, name: 'SmartFIX', tag: 'haynes'},
        {id: -1, name: 'Recalls', tag: 'haynes'},
        {id: -2, name: 'SmartCASE', tag: 'haynes'},
        {id: 1, name: 'Airbag', tag: 'airbag'},
        {id: 2, name: 'Alumbrado', tag: 'lights'},
        {id: 3, name: 'Carrocería', tag: 'exterior'},
        {id: 4, name: 'Cierre', tag: 'doorlock'},
        {id: 5, name: 'Climatización', tag: 'climate'},
        {id: 6, name: 'Confort', tag: 'interior'},
        {id: 7, name: 'Dirección', tag: 'steer'},
        {id: 8, name: 'Frenos', tag: 'brakes'},
        {id: 9, name: 'Inmovilizador', tag: 'key'},
        {id: 10, name: 'Mantenimiento', tag: 'revision'},
        {id: 11, name: 'Motor', tag: 'engine'},
        {id: 12, name: 'Tracción', tag: 'tires'},
        {id: 13, name: 'Suspensión', tag: 'suspension'},
        {id: 14, name: 'Transmisión', tag: 'trans'},
        {id: 15, name: 'Híbrido/Eléctrico', tag: 'electric'}
      ],
      counts: []
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'vehicle',
      'category'
    ]),
    categoryName() {
      return this.categories.filter(item => {
        return item.id == this.category
      })[0].name
    }
  },
  methods: {
    setCategory(category) {
      let name = this.categories.filter(item => {
        return item.id === parseInt(category);
      })[0].name;
      this.$store.commit('CHANGE_CATEGORY', [category, name])
      let nextView = category < 1 ? 5 : 4
      this.$store.commit('CHANGE_TSB_VIEW', nextView)
    },
    getCount(category) {
      if (!this.counts.length) { return false }
      this.counts.forEach(item => {
        if (item.category === category) {
          return true
        }
      })
      return false
    },
  },
  created() {
    if (!this.vehicle.vehicleID) {
      this.$store.commit('CHANGE_TSB_VIEW', 0)
    } else {
      api.SELECT_VEHICLE(this.vehicle.makerID, this.vehicle.modelID, this.vehicle.vehicleID)
      api.COUNT_BULLETINS(this.vehicle.vehicleID)
          .then(response => {
            if (!response.data.auth) {
              this.logout()
            } else {
              this.counts = response.data.list
            }
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
</style>