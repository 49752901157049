<template>
  <div class="container-fluid">
    <h1>Cursos de gestión</h1>
    <div class="container">
      <table class="table table-dark table-striped table-hover">
        <tbody style="cursor:pointer">
        <tr @click="goTo(f, dir+'/'+f+'.pdf')" v-for="f in files" :key="f">
          <td>{{ f }}</td>
        </tr>
        </tbody>
      </table>

<!--      <v-divider></v-divider>-->

      <!-- TODO SELECTOR COMPONENT -->
<!--      <lesson-selector v-show="0" title="management" dir="management"></lesson-selector>-->

    </div>
    <docs-viewer :title="title" :url="url" ref="viewer"></docs-viewer>
  </div>
</template>

<script>
import DocsViewer from "@/components/DocsViewer.vue";
import api from "@/api";

export default {
  name: "management-lessons-view",
  components: {DocsViewer},
  data() {
    return {
      sections: [

      ],
      dir: 'management',
      files: [],
      viewer: false,
      title: '',
      url: ''
    }
  },
  methods: {
    getDir() {
      api.GET_LESSONS_FILES('management')
          .then(response => {
        if (!response.data.auth) {
          this.$emit('logout')
        } else {
          this.files = response.data.list
        }
      });
    },
    goBack() {
      this.files = [];
      this.dir = '';
    },
    goTo(title, url) {
      this.title = title
      this.url = 'https://docs.infotechnic.es/' + url
      this.$refs.viewer.show()
    },
  },
  created() {
    this.getDir()
  },
}
</script>
